module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tera-Kana","short_name":"GatsbyJS","icon":"src/images/Icon.png","start_url":"/","background_color":"#696737","theme_color":"#FFFFFF","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"baebc78dd7dfae26123420f220409bd4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["fr","en","es"],"defaultLanguage":"fr","siteUrl":"http://tera-kana.smala.co/","generateDefaultLanguagePage":true,"redirect":false,"i18nextOptions":{"interpolation":{"escapeValue":true},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang/","getLanguageFromPath":true,"languages":["fr","en","es"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
